import React from 'react';

import { Brand } from 'common/styled';

import { ReactComponent as LogoImg } from 'assets/logo.svg';
import { AuthenticationContext } from 'utils';

const LogoContainer = () => {
    const { tenantConfig } = React.useContext(AuthenticationContext);
    return (
        <Brand>
            {tenantConfig.logo ? (
                <img className="logo" src={`${tenantConfig.logo}`} alt="" />
            ) : (
                <>
                    <LogoImg
                        className="logo"
                        style={{ width: '50px', height: '50px' }}
                    />
                    True Login
                </>
            )}
        </Brand>
    );
};

export default LogoContainer;
