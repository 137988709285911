import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { createBrowserHistory } from 'history';
import { notification } from 'antd';

import './App.css';
import * as themes from 'theme';

import axios from 'axios.config';

import { AuthenticationContext } from 'utils';
import RestrictedRoute, {
    LOGGED_IN_USERS,
    LOGGED_OUT_USERS,
} from 'components/RestrictedRoute';
import ConditionalRoute from 'components/ConditionalRoute';

import LoadingScreen from 'components/LoadingScreen';
import LandingPage from 'pages/Landing';
import HomePage from './pages/Home';
import TryItNowPage from './pages/TryItNow';
import LoginPage from 'pages/LoginPage';
import RegistrationPage from 'pages/RegisterPage';
import ForgotPasswordPage from 'pages/ForgotPassword';
import ResetPasswordLink from 'pages/ResetPasswordLink';
import EmailVerification from 'pages/EmailVerification';

export const history = createBrowserHistory();

function App() {
    const [authenticated, setAuthenticated] = React.useState(false);
    const [user, setUser] = React.useState<any>(null);
    const [theme, setTheme] = React.useState<
        'blueTheme' | 'purpleTheme' | 'darkTheme' | 'blackWhiteTheme'
    >('blackWhiteTheme');

    const [tenantConfig, setTenantConfig] = React.useState({});
    const [emailVerified, setEmailVerified] = React.useState(null);
    const [isScreenLoading, setScreenLoading] = React.useState(false);

    const fetchTenantConfig = async (tenant_name: string) => {
        try {
            const response = await axios.get(`/tenant/${tenant_name}`);

            const config = response.data;

            setTheme(config.data.theme);

            setTenantConfig(config.data);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        const subdomain = window.location.href.split('/')[2].split('.')[0];

        fetchTenantConfig(subdomain);
    }, []);

    const checkForSession = async () => {
        // setScreenLoading(true);
        try {
            const data = JSON.parse(
                sessionStorage.getItem('user') || JSON.stringify({})
            );

            if (data && (data.id || data.sub)) {
                if (data.sub) {
                    setUser({
                        ...data,
                        id: data.sub,
                    });
                } else {
                    setUser(data);
                }

                notification.success({
                    message: 'Successfully logged in!',
                });

                setAuthenticated(true);
            }
        } catch (error) {
            console.error(error.response?.data);

            // notification.error({
            //     message: error.response?.data ?? 'Session not found',
            // });
        } finally {
            // setScreenLoading(false);
        }
    };

    React.useEffect(() => {
        checkForSession();
    }, []);

    return (
        <AuthenticationContext.Provider
            value={{
                authenticated,
                emailVerified,
                setEmailVerified,
                setAuthenticated,
                user,
                setUser,
                tenantConfig,
                isScreenLoading,
                setScreenLoading,
            }}
        >
            <ThemeProvider theme={themes[theme]}>
                <Router history={history}>
                    <Switch>
                        {isScreenLoading && (
                            <Route exact path="/" component={LoadingScreen} />
                        )}

                        <ConditionalRoute
                            exact
                            path="/"
                            loginComponent={HomePage}
                            logoutComponent={LandingPage}
                        />

                        <Route exact path="/" component={LandingPage} />
                        <Route path="/tryit" component={TryItNowPage} />
                        <RestrictedRoute
                            path="/reset-password-link/:token"
                            component={ResetPasswordLink}
                            onlyAllow={LOGGED_OUT_USERS}
                        />
                        <RestrictedRoute
                            path="/email-verification/:token"
                            component={EmailVerification}
                            onlyAllow={LOGGED_OUT_USERS}
                        />
                        <RestrictedRoute
                            exact
                            path="/login"
                            component={LoginPage}
                            onlyAllow={LOGGED_OUT_USERS}
                        />
                        <RestrictedRoute
                            path="/register"
                            component={RegistrationPage}
                            onlyAllow={LOGGED_OUT_USERS}
                        />
                        <RestrictedRoute
                            path="/forgotpassword"
                            component={ForgotPasswordPage}
                            onlyAllow={LOGGED_OUT_USERS}
                        />
                        <RestrictedRoute
                            path="/"
                            component={HomePage}
                            onlyAllow={LOGGED_IN_USERS}
                        />
                    </Switch>
                </Router>
            </ThemeProvider>
        </AuthenticationContext.Provider>
    );
}

export default App;
