import React from 'react';

import { AuthenticationContext } from 'utils';

import jwtDecode from 'jwt-decode';

import { Wrapper, FormContainer, Footer, TextMuted } from './styled';

import Logo from 'components/Logo';

const LandingPage = () => {
    const { setAuthenticated, setUser, tenantConfig, setScreenLoading } =
        React.useContext(AuthenticationContext);

    React.useEffect(() => {
        const hashedToken = window.location.hash;

        if (hashedToken !== '') {
            setScreenLoading(false);
            const id_token = window.location.hash.split('=')[1];

            window.location.hash = '';

            try {
                const decodedUser: any = jwtDecode(id_token);

                setAuthenticated(true);

                setUser({
                    email: decodedUser.email,
                    id: decodedUser.sub,
                });

                sessionStorage.setItem('user', JSON.stringify(decodedUser));
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('check-login')) {
            setScreenLoading(true);
            window.location.href = `https://gslab-instance-dev.onelogin.com/oidc/2/auth?client_id=c280b4c0-b48c-0139-b237-028559619631125300&redirect_uri=${process.env.REACT_APP_UI_DOMAIN}&response_type=id_token&scope=openid&nonce=112121`;
        }
    }, []);

    return (
        <Wrapper
            style={{ backgroundImage: `url(${tenantConfig.background_image})` }}
        >
            <div className="sid-layy"> </div>

            <FormContainer>
                <Logo />

                <Footer style={{ justifyContent: 'center' }}>
                    <TextMuted to="/login"> Go to login</TextMuted>
                </Footer>
            </FormContainer>
        </Wrapper>
    );
};

export default LandingPage;
