import React, { useContext } from 'react';
import { Route, Switch } from 'react-router';
import Axios from 'axios';

import { Card, CardBody } from './styled';
import { AuthenticationContext } from '../../utils';
import ChangePasswordPage from '../../pages/ChangePassword';
import ChangePerferencePage from '../../pages/ChangePreferences';

const ProfileContent = () => {
    return (
        <>
            <Card style={{ alignItems: 'center' }} to="/profile/changepassword">
                <CardBody>
                    <span>Change</span> <span>Password</span>
                </CardBody>
            </Card>
            <Card
                style={{ alignItems: 'center' }}
                to="/profile/changepreferences"
            >
                <CardBody>
                    <span>Change</span> <span>user preferences</span>
                </CardBody>
            </Card>
        </>
    );
};

const Profile = (props: any) => {
    const { match } = props;
    const { user, setUser } = useContext(AuthenticationContext);

    React.useEffect(() => {
        const get_user_info = async () => {
            try {
                //----------------------------------------------------------------
                // Get user by id
                //----------------------------------------------------------------
                const { id } = user;
                const response = await Axios.get(`user/${id}`);
                setUser(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        if (!user) {
            get_user_info();
        }
    }, [setUser, user]);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Switch>
                <Route
                    exact
                    path={`${match.url}/changepassword`}
                    component={ChangePasswordPage}
                />
                <Route
                    exact
                    path={`${match.url}/changepreferences`}
                    component={ChangePerferencePage}
                />
                <Route exact path={match.url} component={ProfileContent} />
            </Switch>
        </div>
    );
};

export default Profile;
