import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { HeaderContainer, DropdownContent, Brand } from './styled';
import { Dropdown, Avatar, Menu } from 'antd';

import { ReactComponent as LogoImg } from 'assets/logo.svg';

import { UserOutlined, DownOutlined } from '@ant-design/icons';

import { history } from 'App';
import Logout from '../Logout';
import { AuthenticationContext } from 'utils';

const menu = (
    <Menu>
        <Menu.Item key="1">
            <Link to="/profile"> Profile </Link>
        </Menu.Item>
        <Menu.Item key="2">
            <Logout />
        </Menu.Item>
    </Menu>
);

const Header = () => {
    const { tenantConfig } = React.useContext(AuthenticationContext);

    const redirectToLoginPage = () => {
        history.replace('/');
    };

    // return <AntdHeader> <Logo > LOGO </Logo> <LogoutButton />   </AntdHeader>
    return (
        <HeaderContainer>
            <Brand onClick={redirectToLoginPage}>
                {tenantConfig.logo ? (
                    <img src={tenantConfig.logo} className="logo" />
                ) : (
                    <>
                        <LogoImg className="logo" style={{ width: '50px' }} />
                        True Login
                    </>
                )}
            </Brand>

            <Dropdown overlay={menu} placement="bottomCenter">
                <DropdownContent>
                    <Avatar
                        icon={<UserOutlined />}
                        style={{ marginRight: '10px' }}
                    />
                    <DownOutlined style={{ color: '#fff' }} />
                </DropdownContent>
            </Dropdown>
        </HeaderContainer>
    );
};

export default Header;
