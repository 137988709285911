import { styled } from 'theme';

import { Select as AntdSelect } from 'antd';

// import { Link } from 'react-router-dom';

// export const Wrapper = styled.div`
//     width: 100%;
//     height: 100%;
//     position: relative;
// `;

// export const Background = styled.img`
//     position: absolute;
//     width: 100%;
//     max-height: 100%;
// `;

// export const Backdrop = styled.div`
//     width: 100%;
//     min-height: 100vh;
//     background: #2a323c;
//     z-index: 10;
// `;

// export const ButtonWrapper = styled.div`
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     display: flex;
//     justify-content: space-evenly;
//     width: 40%;
// `;

// export const StyledLink = styled(Link)`
//     font-size: 20px;
//     max-width: 150px !important;
//     text-align: center;
//     text-decoration: none;

//     color: #ffffff;
//     background-color: #04a2b3 !important;
//     border: 2px solid #04a2b3 !important;
//     border-radius: 3px;
//     transition: 10ms ease-in;

//     padding: 10px 20px;
//     cursor: pointer;

//     &:focus {
//         outline: none;
//     }
//     &:hover {
//         color: #ffffff;
//     }
// `;

export const Select = styled(AntdSelect)<any>`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: ${({ theme }) => theme.form.input.focusedBorderColor};
    }
    border: 2px solid ${({ theme }) => theme.form.input.borderColor};

    box-shadow: none;

    &:hover {
        border-color: ${({ theme }) => theme.form.input.hoverBorderColor};
    }

    &:focus {
        border-color: ${({ theme }) => theme.form.input.focusedBorderColor};
    }
`;

export * from 'common/styled';
