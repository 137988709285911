import React, { ReactNode, useContext } from 'react';
import { Checkbox, notification } from 'antd';
import {
    Wrapper,
    ButtonContainer,
    FormContainer,
    FormBody,
    FormHeader,
    Button,
    Footer,
    Select,
    QuestionsContainer,
    QuestionBody,
    QuestionIndex,
    AnswerPortion,
} from './styled';

import Spinner, { SpinnerWrapper } from 'components/LoadingScreen/Loader';

import { history } from 'App';

import services from './services';
import { AuthenticationContext } from 'utils';

const { Option } = Select;
const { Item } = FormBody;

const preferenceQuestions = [
    {
        id: 1,
        question: 'Do you want to get notifications on your mail?',
        answer: (
            <Item name="emailNotify" valuePropName="checked">
                <Checkbox></Checkbox>
            </Item>
        ),
    },
    {
        id: 2,
        question: 'Do you want to get notifications of our new arrivals?',
        answer: (
            <Item name="newArrivals" valuePropName="checked">
                <Checkbox />
            </Item>
        ),
    },
    {
        id: 3,
        question: 'Which collections you like the most?',
        answer: (
            <Item
                name="favBrand"
                style={{ width: '90%' }}
                rules={[
                    {
                        required: true,
                        message:
                            'Please select one of your favourite collections!',
                    },
                ]}
            >
                <Select placeholder="Select">
                    <Option value="footwear"> Footwear</Option>
                    <Option value="shirts"> Shirts</Option>
                    <Option value="trousers"> Trousers</Option>
                </Select>
            </Item>
        ),
    },
];

const ChangePreference = (props: any) => {
    const [form] = FormBody.useForm();
    const { user, token, tenantConfig } = useContext(AuthenticationContext);
    const [loading, setLoading] = React.useState(false);
    const [defaultValue, setDefaultValue] = React.useState({});

    React.useEffect(() => {
        const fetchCustomAttributes = () => {
            setLoading(true);
            services
                .fetchCustomAttributes(user.id)
                .then((res) => {
                    const { custom_attributes } = res.data;

                    setDefaultValue({
                        ...custom_attributes,
                        newArrivals: custom_attributes.newArrivals === 't',
                        emailNotify: custom_attributes.emailNotify === 't',
                    });
                })
                .catch((error) => {
                    notification.error({
                        message:
                            error.response?.data || 'Unable to reset password!',
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        fetchCustomAttributes();
    }, [token, user.id]);

    const onBack = () => {
        history.goBack();
    };

    const submitHandler = () => {
        form.validateFields()
            .then((res: any) => {
                setLoading(true);
                services
                    .updatedCustomAttributes(res, user.id)
                    .then((res: any) => {
                        notification.success({
                            message: 'Preference updation complete!',
                        });
                        props.history.replace('/');
                    })
                    .catch((error) => {
                        notification.error({
                            message:
                                error.response?.data ||
                                'Unable to update preferences, Try again!',
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch((error: any) => {
                console.error(error);
                notification.error({
                    message: 'Please enter required fields',
                });
            });
    };

    if (loading) {
        return (
            <Wrapper
                style={{
                    height: 'calc(100vh - 50px)',
                    backgroundImage: `url(${tenantConfig.background_image})`,
                }}
            >
                <div className="sid-layy"></div>
                <FormContainer>
                    <FormHeader>Update Preferences</FormHeader>
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                </FormContainer>
            </Wrapper>
        );
    }

    return (
        <Wrapper
            style={{
                height: 'calc(100vh - 50px)',
                backgroundImage: `url(${tenantConfig.background_image})`,
            }}
        >
            <div className="sid-layy"></div>
            <FormContainer>
                <FormHeader>Update Preferences</FormHeader>
                <FormBody initialValues={defaultValue} form={form}>
                    <QuestionsContainer>
                        {preferenceQuestions.map(
                            (
                                ques: {
                                    id: number;
                                    question: string;
                                    answer: ReactNode;
                                },
                                index: number
                            ) => {
                                return (
                                    <QuestionBody key={ques.id}>
                                        <QuestionIndex>
                                            {index + 1}.
                                        </QuestionIndex>
                                        {ques.question}
                                        <AnswerPortion>
                                            {ques.answer}
                                        </AnswerPortion>
                                    </QuestionBody>
                                );
                            }
                        )}
                    </QuestionsContainer>

                    <Footer>
                        <ButtonContainer>
                            <Button
                                color="danger"
                                size="large"
                                onClick={onBack}
                                style={{ width: '45%' }}
                            >
                                Back
                            </Button>
                            <Button
                                onClick={submitHandler}
                                size="large"
                                style={{ width: '45%' }}
                            >
                                Submit
                            </Button>
                        </ButtonContainer>
                    </Footer>
                </FormBody>
            </FormContainer>
        </Wrapper>
    );
};

export default ChangePreference;
