import axios from 'axios.config';
import React, { useContext } from 'react';
import { notification, Button as AntdButton } from 'antd';
import styled from 'styled-components';
import { AuthenticationContext, deleteSession } from 'utils';

const LogoutButton = () => {
    const { setAuthenticated, setScreenLoading, user } = useContext(
        AuthenticationContext
    );

    const logOut = async () => {
        setScreenLoading(true);
        const { id } = user;
        await axios
            .get(`logout/${id}`)
            .then((res) => {
                notification.success({
                    message: 'User successfully Logged out!',
                });
                deleteSession();
                setAuthenticated(false);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setScreenLoading(false);
            });
    };

    return <div onClick={logOut}>Log Out</div>;
};

export default LogoutButton;
