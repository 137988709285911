import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const screenSize = {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
};

interface Theme {
    primaryColor: string;
    secondaryColor: string;

    backgroundColor: string;
    loader: {
        gapColor: string;
        borderColor: string;
    };

    login: {
        layoutBackgroundColor: string;
        shadow: string;
    };

    brand: {
        logoColor: string;
        fontColor: string;
    };

    form: {
        backgroundColor: string;
        shadow: string;

        input: {
            borderColor: string;
            backgroundColor: string;
            hoverBorderColor: string;
            fontColor: string;
            focusedBorderColor: string;
        };
        instruction: {
            borderColor: string;
            fontColor: string;
            backgroundColor: string;
        };
        divider: {
            borderColor: string;
            textBackgroundColor: string;
            textFontColor: string;
        };
        headerFontColor: string;
        textMutedColor: string;
        questionBodyFontColor: string;
    };

    homePageContent: {
        fontColor: string;
        card: {
            fontColor: string;
            borderColor: string;
        };
    };

    button: {
        primary: string;
        secondary: string;
        success: string;
        danger: string;
        primaryFontColor: string;
        secondaryFontColor: string;
        shadow: string;
        borderColor: string;
    };

    header: {
        backgroundColor: string;
        fontColor: string;
    };
}

const color = {
    blue: '#007bff',
    indigo: '#6610f2',
    purple: 'rgba(135, 63, 217, 0.73)',
    pink: '#e83e8c',
    red: '#dc3545',
    orange: '#fd7e14',
    yellow: '#ffc107',
    green: '#28a745',
    teal: '#20c997',
    cyan: '#17a2b8',
    white: '#fff',
    gray: '#6c757d',
    grayDark: '#343c48',
    secondary: '#6c757d',
    success: '#28a745',
    info: '#17a2b8',
    warning: '#ffc107',
    danger: '#dc3545',
    light: '#f8f9fa',
    dark: '#343a40',
    black: '#000000',
    secondaryButton: '#7E7C8A',
};

export const purpleTheme: Theme = {
    primaryColor: '#fff',
    secondaryColor: color.grayDark,
    backgroundColor: '#fff',

    loader: {
        gapColor: color.white,
        borderColor: color.purple,
    },

    button: {
        primary: color.purple,
        secondary: color.secondaryButton,
        success: color.success,
        danger: color.danger,
        primaryFontColor: color.white,
        secondaryFontColor: color.white,
        shadow: '0px 3px 3px #00000029',
        borderColor: color.purple,
    },

    header: {
        fontColor: color.white,
        backgroundColor: color.purple,
    },

    homePageContent: {
        fontColor: color.purple,
        card: {
            fontColor: color.purple,
            borderColor: color.purple,
        },
    },

    login: {
        layoutBackgroundColor: 'rgba(135, 63, 217, 0.30)',
        shadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    },

    form: {
        backgroundColor: '#F7F7F7',
        shadow: 'none',

        input: {
            borderColor: '#ccc',
            backgroundColor: '#fff ',
            fontColor: color.purple,
            hoverBorderColor: color.purple,
            focusedBorderColor: color.purple,
        },
        instruction: {
            borderColor: color.purple,
            fontColor: color.purple,
            backgroundColor: color.white,
        },
        divider: {
            borderColor: color.purple,
            textBackgroundColor: '#f7f7f7',
            textFontColor: color.purple,
        },
        headerFontColor: '#000',
        textMutedColor: '#000',
        questionBodyFontColor: color.purple,
    },
    brand: {
        logoColor: color.purple,
        fontColor: color.purple,
    },
};

export const blueTheme: Theme = {
    primaryColor: '#fff',
    secondaryColor: color.grayDark,
    backgroundColor: '#f7f9fa',

    loader: {
        gapColor: color.white,
        borderColor: color.blue,
    },

    button: {
        primary: color.blue,
        secondary: color.secondaryButton,
        success: color.success,
        danger: color.danger,
        primaryFontColor: color.white,
        secondaryFontColor: color.white,
        shadow: '0px 3px 3px #00000029',
        borderColor: color.blue,
    },

    homePageContent: {
        fontColor: color.black,
        card: {
            fontColor: color.black,
            borderColor: color.black,
        },
    },

    header: {
        fontColor: color.blue,
        backgroundColor: color.white,
    },

    login: {
        layoutBackgroundColor: 'rgba(0, 164, 254, 0.23)',
        shadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    },
    brand: {
        logoColor: color.blue,
        fontColor: color.blue,
    },

    form: {
        backgroundColor: '#fff',
        shadow: ' 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',

        input: {
            borderColor: '#ccc',
            backgroundColor: '#fff ',
            fontColor: color.blue,
            hoverBorderColor: color.blue,
            focusedBorderColor: color.blue,
        },
        divider: {
            borderColor: '#ccc',
            textBackgroundColor: '#fff',
            textFontColor: color.black,
        },
        instruction: {
            borderColor: color.blue,
            fontColor: color.blue,
            backgroundColor: color.white,
        },
        headerFontColor: '#000',
        textMutedColor: '#000',
        questionBodyFontColor: color.blue,
    },
};

export const blackWhiteTheme: Theme = {
    primaryColor: '#fff',
    secondaryColor: color.grayDark,
    backgroundColor: '#f7f9fa',

    loader: {
        gapColor: color.white,
        borderColor: color.black,
    },

    button: {
        primary: color.black,
        secondary: color.secondaryButton,
        success: color.success,
        danger: color.danger,
        primaryFontColor: color.white,
        secondaryFontColor: color.white,
        shadow: '0px 3px 3px #00000029',
        borderColor: color.blue,
    },

    homePageContent: {
        fontColor: color.black,
        card: {
            fontColor: color.black,
            borderColor: color.black,
        },
    },

    header: {
        fontColor: color.black,
        backgroundColor: '#f7f7f7',
    },

    login: {
        layoutBackgroundColor: 'rgba(0,0, 0, 0.23)',
        shadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    },
    brand: {
        logoColor: color.black,
        fontColor: color.black,
    },

    form: {
        backgroundColor: '#f7f7f7',
        shadow: ' 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',

        input: {
            borderColor: '#ccc',
            backgroundColor: '#fff ',
            fontColor: color.black,
            hoverBorderColor: color.black,
            focusedBorderColor: color.black,
        },
        divider: {
            borderColor: '#ccc',
            textBackgroundColor: '#f7f7f7',
            textFontColor: color.black,
        },
        instruction: {
            borderColor: color.black,
            fontColor: color.black,
            backgroundColor: color.white,
        },
        headerFontColor: '#000',
        textMutedColor: '#000',
        questionBodyFontColor: '#000000',
    },
};

export const darkTheme: Theme = {
    primaryColor: '#2a323c',
    secondaryColor: color.grayDark,
    backgroundColor: '#2a323c',

    loader: {
        gapColor: color.grayDark,
        borderColor: '#04a2b3',
    },

    button: {
        primary: '#04a2b3',
        secondary: color.secondaryButton,
        success: color.success,
        danger: color.danger,
        primaryFontColor: color.white,
        secondaryFontColor: color.white,
        shadow: '0px 3px 3px #00000029',
        borderColor: '#04a2b3',
    },
    header: {
        fontColor: color.white,
        backgroundColor: 'rgb(108, 117, 125)',
    },

    homePageContent: {
        fontColor: color.white,
        card: {
            fontColor: color.white,
            borderColor: color.white,
        },
    },

    form: {
        backgroundColor: color.grayDark,
        shadow: '0px 1px 2px 0px rgb(0 0 0 / 10%)',

        input: {
            borderColor: '#4a5869',
            backgroundColor: '#3b4654 ',
            fontColor: color.white,
            hoverBorderColor: '#4a5869',
            focusedBorderColor: 'rgb(133, 133, 133)',
        },
        divider: {
            borderColor: '#ccc',
            textBackgroundColor: color.grayDark,
            textFontColor: color.white,
        },
        instruction: {
            borderColor: color.cyan,
            fontColor: color.cyan,
            backgroundColor: color.grayDark,
        },
        headerFontColor: '#98a4b9',
        textMutedColor: '#98a4b9',
        questionBodyFontColor: '#98a4b9',
    },
    login: {
        layoutBackgroundColor: 'rgba(42,50,60,0.73)',
        shadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    },
    brand: {
        logoColor: '#fff',
        fontColor: '#fff',
    },
};

export const styled = baseStyled as ThemedStyledInterface<Theme>;
