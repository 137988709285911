import React from 'react';
import axios from 'axios.config';
import {
    FormContainer,
    Wrapper,
    FormHeader,
    FormBody,
    Button,
    Input,
    InstructionText,
    ButtonContainer,
    Footer,
    TextMuted,
    Image,
    SendEmailBody,
} from './styled';

import { AuthenticationContext } from 'utils';

import Logo from 'components/Logo';

import EmailIcon from 'assets/email.png';

import { notification } from 'antd';

const { Item } = FormBody;

const ForgotPassword = () => {
    const [form] = FormBody.useForm();

    const { tenantConfig } = React.useContext(AuthenticationContext);
    const [emailSent, setEmailSent] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const submitHandler = async (values: any) => {
        setLoading(true);

        console.log(values);

        try {
            await axios.post(
                'reset-password-link',
                {
                    email: values.email,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setEmailSent(true);
        } catch (error) {
            notification.error({
                message:
                    error.response?.data?.message ?? 'Something went wrong',
            });
            setEmailSent(false);
        } finally {
            setLoading(false);
        }
    };

    if (emailSent) {
        return (
            <Wrapper
                style={{
                    backgroundImage: `url(${tenantConfig.background_image})`,
                }}
            >
                <div className="sid-layy"></div>

                <FormContainer>
                    <SendEmailBody>
                        <Image src={EmailIcon} />
                        <p> Reset Password link has been sent on your mail. </p>
                    </SendEmailBody>
                </FormContainer>
            </Wrapper>
        );
    }

    return (
        <Wrapper
            style={{ backgroundImage: `url(${tenantConfig.background_image})` }}
        >
            <div className="sid-layy"></div>
            <FormContainer>
                <Logo />
                <FormHeader> Reset Password</FormHeader>

                <FormBody form={form} onFinish={submitHandler}>
                    <InstructionText>
                        Enter your <strong>Email</strong> and instruction will
                        be sent to you!
                    </InstructionText>
                    <Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Email is required',
                            },
                            {
                                type: 'email',
                                message: 'Please enter valid email',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Email"
                            size="large"
                            spellCheck="false"
                        />
                    </Item>

                    <ButtonContainer
                        style={{ justifyContent: 'space-between' }}
                    >
                        <Button
                            size="large"
                            htmlType="submit"
                            loading={loading}
                        >
                            Send Mail
                        </Button>
                    </ButtonContainer>
                </FormBody>
                <Footer style={{ justifyContent: 'center' }}>
                    <TextMuted to="/login">Back to login</TextMuted>
                </Footer>
            </FormContainer>
        </Wrapper>
    );
};

export default ForgotPassword;
