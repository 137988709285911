import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

export const InstructionText = styled.span`
    display: block;
    background-color: ${({ theme }) => theme.form.instruction.backgroundColor};

    color: ${({ theme }) => theme.form.instruction.fontColor};
    border: 1px dashed ${({ theme }) => theme.form.instruction.borderColor};
    position: relative;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    padding: 5px 10px;
`;

export const CloseButton = styled(CloseOutlined)`
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 500;
    cursor: pointer;
`;

export const SpinnerWrapper = styled.div`
    padding: 15px;
    display: flex;
    justify-content: center;
`;

export const Image = styled.img`
    width: 64px;
    text-align: center;
`;

export const SendEmailBody = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
        margin-top: 20px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
`;

export * from 'common/styled';
