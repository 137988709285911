import axios from 'axios.config';
import Axios from 'axios';

const service = {
    signup: async (data: any) => {
        const response = await axios.post(`user`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data;
    },
    login: async (username: any, password: any) => {
        const response = await axios.post('auth', {
            username,
            password,
        });
        return response.data;
    },

    create_session: async (sessionToken: any) => {
        const url = `https://gslab-instance-dev.onelogin.com/session_via_api_token`;
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: '*/*',
            },
            withCredentials: true,
        };

        const response = await Axios.post(
            url,
            { session_token: sessionToken },
            axiosConfig
        );
        return response;
    },

    sendEmailVerificationMail: async (email: any) => {
        const response = await axios.post('/send-email-verification-mail', {
            email,
        });

        return response.data;
    },
};

export default service;
