import Text from 'antd/lib/typography/Text';
import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);

    border-top: 4px solid ${(props) => props.theme.loader.borderColor};
    border-right: 4px solid ${(props) => props.theme.loader.borderColor};
    border-bottom: 4px solid ${(props) => props.theme.loader.borderColor};
    border-left: 4px solid ${(props) => props.theme.loader.gapColor};
    background: transparent;
    width: 120px;
    height: 120px;
    border-radius: 50%;
`;

export const SpinnerWrapper = styled.div`
    padding: 15px;
    display: flex;
    justify-content: center;
`;

export const Backdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background: ${(props) => props.theme.loader.gapColor};
`;

export const SpinnerContainer = styled.div`
    width: 300px;
    height: 300px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const Loadtext = styled(Text)`
    position: absolute;
    top: 50%;
    left: 50%;
    text-transform: uppercase;
    color: ${(props) => props.theme.loader.borderColor};
    font-size: 18px;
    transform: translate(-50%, -50%);
`;

export default Spinner;
