import { styled } from 'theme';
import Text from 'antd/lib/typography/Text';
import {
    Button as AntdButton,
    Form,
    Input as AntdInput,
    Select as AntdSelect,
} from 'antd';

import BackgroundImg from 'assets/16023.jpg';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
    box-shadow: ${({ theme }) => theme.login.shadow};
    left: 0;
    background-image: url(${BackgroundImg});
    background-repeat: no-repeat;
    background-size: cover;
    right: 0;

    .sid-layy {
        background-color: ${({ theme }) => theme.login.layoutBackgroundColor};
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
`;

export const FormContainer = styled.div`
    background-color: ${({ theme }) => theme.form.backgroundColor};
    width: 50%;
    min-height: 290px;
    padding: 25px;
    box-shadow: ${({ theme }) => theme.form.shadow};
    border-radius: 0.25rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 2;

    @media (min-width: 500px) {
        width: 420px;
    }

    @media (max-width: 500px) {
        width: 90%;
    }
`;

export const FormBody = styled(Form)`
    padding: 15px 10px;
`;

const getColor = (type?: any): string => {
    switch (type.color) {
        case 'success':
            return type.theme.button.success;
        case 'danger':
            return type.theme.button.danger;
        default:
            return type.theme.button.primary;
    }
};

export const Button = styled(AntdButton)<{ color?: String }>`
    background-color: ${(props) => getColor(props)};
    border: 1px solid ${(props) => getColor(props)} !important;
    color: #ffffff;
    width: 100%;
    margin-bottom: 15px;
    &:hover {
        background-color: ${(props) => getColor(props)};
        color: #ffffff;
    }

    &:focus {
        background: ${(props) => getColor(props)};
        color: #ffffff;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 30px;
`;

export const Input = styled(AntdInput)`
    background-color: ${({ theme }) =>
        theme.form.input.backgroundColor} !important;
    color: ${({ theme }) => theme.form.input.fontColor};
    border: 2px solid ${({ theme }) => theme.form.input.borderColor};
    border-radius: 5px;

    &:hover {
        border-color: ${({ theme }) => theme.form.input.hoverBorderColor};
    }
    &:focus {
        border-color: ${({ theme }) => theme.form.input.focusedBorderColor};
        box-shadow: none;
    }
`;

export const FormHeader = styled(Text)`
    font-size: 14px;
    padding: 10px 0;
    text-align: center;
    color: ${({ theme }) => theme.form.headerFontColor} !important;
    display: block;
`;

export const Brand = styled.div`
    padding: 10px 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }) => theme.brand.fontColor};
    text-align: center;

    .logo {
        fill: ${({ theme }) => theme.brand.logoColor};
        height: 50px;
        width: 140px;
    }
`;

export const TextMuted = styled(Link)`
    font-size: 14px;
    color: ${({ theme }) => theme.form.textMutedColor} !important;
    text-decoration: none;
    background-color: transparent;
`;

export const Logo = styled.img`
    height: 30px;
    margin: 10px;
`;

export const QuestionsContainer = styled.div`
    padding: 0 10px;
`;

export const QuestionBody = styled.div`
    display: grid;
    grid-template-columns: 8% 70% 32%;
    color: ${({ theme }) => theme.form.questionBodyFontColor} !important;
    margin-bottom: 15px;
`;

export const AnswerPortion = styled.div`
    text-align: center;
`;

export const QuestionIndex = styled.div``;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`;

export const Select = styled(AntdSelect)``;

export const SocialLoginWrapper = styled.div`
    position: relative;
    border-top: 1px solid ${({ theme }) => theme.form.divider.borderColor};
    padding: 20px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 10px;
    justify-items: center;
    align-items: center;
    grid-column-gap: 10px;

    span {
        position: absolute;
        top: -10px;
        color: ${({ theme }) => theme.form.divider.textFontColor};
        left: 50%;
        background-color: ${({ theme }) =>
            theme.form.divider.textBackgroundColor};
        display: block;
        padding: 0 10px;
        transform: translateX(-50%);
    }
`;

export const SocialLogin = styled.div`
    text-align: center;
    padding: 5px;
    text-align: center;
    width: fit-content;
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;

    &:hover {
        img {
            transform: scale(1.1);
        }
    }

    img {
        width: 30px;
    }
`;
