import axios from 'axios.config';

const services = {
    fetchCustomAttributes: async (id: any) => {
        const response = await axios.get(`user/${id}/custom_attributes`);

        return response.data;
    },
    updatedCustomAttributes: async (data: any, id: any) => {
        const response = await axios.put(`user/${id}/custom_attributes`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data;
    },
};

export default services;
