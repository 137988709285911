import { styled } from 'theme';

export const Container = styled.div`
    width: 100vw;
    height: calc(100vh - 50px);
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

export * from 'common/styled';
