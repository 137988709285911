import React from 'react';
import axios from 'axios.config';

import Loader, { SpinnerContainer } from 'components/LoadingScreen/Loader';
import { AuthenticationContext } from 'utils';
import { Card, CardBody, ContentHeader, Body } from './styled';
import { notification, Avatar } from 'antd';

const HomeContent = () => {
    const [apps, setApps] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);
    const { user } = React.useContext(AuthenticationContext);

    const fetchApps = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`user/${user.id}/apps`);

            setApps(response.data.data);
        } catch (error) {
            console.log(error.response);
            notification.error({
                message:
                    error?.response?.data?.message ?? 'Something went wrong',
            });
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchApps();
    }, []);

    if (loading) {
        return (
            <div style={{ padding: '40px 100px' }}>
                <ContentHeader>Applications</ContentHeader>
                <div style={{ display: 'flex', position: 'relative' }}>
                    <SpinnerContainer style={{ top: '110px', left: '100px' }}>
                        <Loader />
                    </SpinnerContainer>
                </div>
            </div>
        );
    }

    if (apps.length === 0) {
        return (
            <div style={{ padding: '40px 100px' }}>
                <ContentHeader>Applications</ContentHeader>

                <Body style={{ padding: '10px' }}>No apps found</Body>
            </div>
        );
    }

    return (
        <div style={{ padding: '40px 100px' }}>
            {/* <div>
                <ContentHeader>
                    Hello <strong> {user.firstname}</strong>
                </ContentHeader>
                <h2> This is the dashboard</h2>
            </div> */}
            <ContentHeader>Applications</ContentHeader>
            <div style={{ display: 'flex' }}>
                {apps.map((app) => {
                    return (
                        <Card
                            to={{
                                pathname: `https://gslab-instance-dev.onelogin.com/launch/${app.id}`,
                            }}
                            target="_blank"
                            key={app.name}
                            style={{ minHeight: '150px' }}
                        >
                            <CardBody>
                                <Avatar
                                    style={{ margin: '20px', fontSize: '20px' }}
                                    size={64}
                                    src={app.icon_url}
                                >
                                    {app.name[0]}
                                </Avatar>
                                {app.name}
                            </CardBody>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};

export default HomeContent;
