// import { Layout } from "antd"
import Header from 'components/Header';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router';

import ProfilePage from 'pages/Profile';
import { Content, Container } from './styled';
import { AuthenticationContext } from 'utils';
import HomeContent from './HomeContent';
import Axios from 'axios.config';
// const {Content} = Layout

const Home = (props: any) => {
    const { match } = props;
    const { user, setUser } = useContext(AuthenticationContext);

    React.useEffect(() => {
        const get_user_info = async () => {
            try {
                //----------------------------------------------------------------
                // Get user by id
                //----------------------------------------------------------------
                const { id } = user;
                const response = await Axios.get(`user/${id}`);
                setUser(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        if (!user) {
            get_user_info();
        }
    }, [setUser, user]);

    return (
        <Container>
            <Header />
            <Content>
                <Switch>
                    <Route
                        exact
                        path={`${match.url}`}
                        component={HomeContent}
                    />
                    <Route
                        path={`${match.url}profile`}
                        component={ProfilePage}
                    />
                </Switch>
            </Content>
        </Container>
    );
};

export default Home;
