import React from 'react';
import axios from 'axios.config';

import { history } from 'App';
import {
    Wrapper,
    FormContainer,
    FormHeader,
    ButtonContainer,
    SpinnerWrapper,
    Button,
    Image,
} from '../ForgotPassword/styled';

import LoadingSpinner from '../../components/LoadingScreen/Loader';
import CheckIcon from '../../assets/check.png';
import CrossIcon from '../../assets/remove.png';

import { notification } from 'antd';
import { AuthenticationContext } from 'utils';

const EmailVerification = (props: any) => {
    const { tenantConfig } = React.useContext(AuthenticationContext);
    const [verificationStatus, setVerificationStatus] = React.useState({
        error: false,
        message: '',
    });

    React.useEffect(() => {
        const { token } = props.match.params;

        if (token === 'in-progress') {
            notification.error({
                message: 'Link is not valid',
            });
        } else {
            props.history.replace({
                pathname: `/email-verification/in-progress`,
            });

            submitHandler(token);
        }
    }, []);
    const redirectToLoginPage = () => history.push('/login');

    const submitHandler = async (token: any) => {
        try {
            const response = await axios.get('/email-verification-link', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            setVerificationStatus({
                ...verificationStatus,
                message: 'success',
            });

            setTimeout(() => {
                redirectToLoginPage();
            }, 5000);
        } catch (error) {
            setVerificationStatus({
                error: true,
                message: 'error',
            });
            notification.error({
                message:
                    error.response?.data?.message ?? 'Something went wrong',
            });
        }
    };

    if (!verificationStatus.error && verificationStatus.message === 'success') {
        return (
            <Wrapper
                style={{
                    backgroundImage: `url(${tenantConfig.background_image})`,
                }}
            >
                <div className="sid-layy"></div>
                <FormContainer
                    style={{ minHeight: '250px', textAlign: 'center' }}
                >
                    <Image src={CheckIcon} />

                    <FormHeader>Verified!</FormHeader>
                    <p style={{ color: '#98a4b9' }}>
                        Your Email has been verified successfully!
                    </p>
                    <ButtonContainer
                        style={{ justifyContent: 'center', marginTop: '20px' }}
                    >
                        <Button onClick={redirectToLoginPage}>
                            Back to home page
                        </Button>
                    </ButtonContainer>
                </FormContainer>
            </Wrapper>
        );
    }

    if (verificationStatus.error) {
        return (
            <Wrapper
                style={{
                    backgroundImage: `url(${tenantConfig.background_image})`,
                }}
            >
                <div className="sid-layy"></div>
                <FormContainer
                    style={{ minHeight: '150px', textAlign: 'center' }}
                >
                    <Image src={CrossIcon} />
                    <FormHeader style={{ fontSize: '22px' }}>
                        Verification link expired
                    </FormHeader>
                </FormContainer>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <div className="sid-layy"></div>
            <FormContainer style={{ minHeight: '150px', textAlign: 'center' }}>
                <FormHeader style={{ fontSize: '22px' }}>
                    Verifing Email
                </FormHeader>
                <SpinnerWrapper>
                    <LoadingSpinner />
                </SpinnerWrapper>
            </FormContainer>
        </Wrapper>
    );
};

export default EmailVerification;
