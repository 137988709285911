import React from 'react';
import FadeIn from 'react-fade-in';
import axios from 'axios.config';

import { history } from 'App';
import {
    Wrapper,
    FormContainer,
    FormBody,
    FormHeader,
    Input,
    Footer,
    ButtonContainer,
    Button,
    Image,
} from '../ForgotPassword/styled';

import CheckIcon from 'assets/check.png';
import { notification } from 'antd';
import { AuthenticationContext } from 'utils';

const { Item } = FormBody;

const ResetPasswordLink = (props: any) => {
    const [form] = FormBody.useForm();

    const { tenantConfig } = React.useContext(AuthenticationContext);
    const [token, setToken] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [resetStatus, setResetStatus] = React.useState({
        error: false,
        message: '',
    });

    React.useEffect(() => {
        const { token } = props.match.params;

        if (token === 'in-progress') {
            notification.error({
                message: 'Link is not valid',
            });
        } else {
            props.history.replace({
                pathname: `/reset-password-link/in-progress`,
            });

            setToken(token);
        }
    }, []);

    const submitHandler = async (values: any) => {
        setLoading(true);
        try {
            const response = await axios.post(
                '/user/reset-password-email',
                {
                    password: values.password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setResetStatus({
                ...resetStatus,
                message: 'success',
            });

            setTimeout(() => {
                redirectToLoginPage();
            }, 3000);
        } catch (error) {
            setResetStatus({
                error: true,
                message: 'error',
            });
            notification.error({
                message:
                    error.response?.data?.message ?? 'Something went wrong',
            });
        } finally {
            setLoading(false);
        }
    };

    const redirectToLoginPage = () => history.push('/login');

    if (!resetStatus.error && resetStatus.message === 'success') {
        return (
            <Wrapper
                style={{
                    backgroundImage: `url(${tenantConfig.background_image})`,
                }}
            >
                <div className="sid-layy"></div>
                <FormContainer style={{ minHeight: '250px' }}>
                    <Image src={CheckIcon} />

                    <FormHeader>Password Changed!</FormHeader>
                    <p style={{ color: '#98a4b9' }}>
                        Your password has been changed successfully!
                    </p>
                    <ButtonContainer
                        style={{ justifyContent: 'center', marginTop: '20px' }}
                    >
                        <Button onClick={redirectToLoginPage}>
                            Back to Login
                        </Button>
                    </ButtonContainer>
                </FormContainer>
            </Wrapper>
        );
    }

    return (
        <Wrapper
            style={{
                backgroundImage: `url(${tenantConfig.background_image})`,
            }}
        >
            <div className="sid-layy"></div>

            <FormContainer style={{ minHeight: '150px' }}>
                <FormHeader style={{ fontSize: '22px' }}>
                    Reset Password
                </FormHeader>
                <FormBody
                    form={form}
                    onFinish={submitHandler}
                    style={{ width: '100%' }}
                >
                    <Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter new password!',
                            },
                        ]}
                    >
                        <Input
                            type="password"
                            placeholder="New Password"
                            autoComplete={'false'}
                        />
                    </Item>
                    <Item
                        name="password_confirmation"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please enter confirm password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            'The two passwords that you entered do not match!'
                                        )
                                    );
                                },
                            }),
                        ]}
                        dependencies={['password']}
                    >
                        <Input
                            type="password"
                            placeholder="Confirm Password"
                            autoComplete={'false'}
                        />
                    </Item>
                    <Footer>
                        <ButtonContainer>
                            <Button
                                style={{ width: '100%' }}
                                size="large"
                                htmlType="submit"
                                loading={loading}
                            >
                                Submit
                            </Button>
                        </ButtonContainer>
                    </Footer>
                </FormBody>
            </FormContainer>
        </Wrapper>
    );
};

export default ResetPasswordLink;
