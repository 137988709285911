import axios from 'axios.config';

const services = {
    resetLink: async (data: any) => {
        const { id, ...restData } = data;
        const response = await axios.post(
            `user/reset-password/${id}`,
            { ...restData },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    },
};

export default services;
