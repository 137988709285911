import styled from 'styled-components';
import { NavLink as RouterNavlink } from 'react-router-dom';

export const Logo = styled.img`
    height: 20px;
`;

export const Profile = styled.div``;

export const NavLink = styled(RouterNavlink)`
    border: 1px solid #ffffff;
    padding: 5px 10px;
    color: #ffffff;
    float: right;
    border-radius: 3px;

    &:active {
        background-color: #ffffff;
        color: ${(props) => props.theme.color.gray};
    }
`;

export const DropdownContent = styled.div`
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Brand = styled.div`
    cursor: pointer;
    padding: 10px 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }) => theme.header.fontColor};
    text-align: center;

    .logo {
        fill: ${({ theme }) => theme.header.fontColor};
        height: 45px;
        width: 130px;
    }
`;

export const HeaderContainer = styled.div`
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 10%);
    padding: 5px 100px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.header.backgroundColor};
    height: 50px;
    justify-content: space-between;
`;
