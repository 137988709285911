import styled from 'styled-components';
import {
    Button as AntdButton,
    Form,
    Input as AntdInput,
    Select as AntdSelect,
} from 'antd';

export const QuestionsContainer = styled.div`
    padding: 0 10px;
`;

export const QuestionBody = styled.div`
    display: grid;
    grid-template-columns: 8% 70% 32%;
    color: #98a4b9 !important;
    margin-bottom: 15px;
`;

export const AnswerPortion = styled.div`
    text-align: center;
`;

export const QuestionIndex = styled.div``;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`;

export const Select = styled(AntdSelect)``;

export const SendEmailBody = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
        margin-top: 20px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
`;

export const Image = styled.img`
    width: 64px;
    text-align: center;
`;

export * from 'common/styled';
