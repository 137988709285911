import { notification } from 'antd';
import React from 'react';

import {
    Wrapper,
    FormContainer,
    FormBody,
    Input,
    Button,
    Footer,
    FormHeader,
    TextMuted,
    QuestionBody,
    QuestionsContainer,
    QuestionIndex,
    ButtonContainer,
    AnswerPortion,
    SendEmailBody,
    Image,
    Select,
} from './styled';

import EmailIcon from 'assets/email.png';

import Logo from 'components/Logo';

import Spinner, { SpinnerWrapper } from 'components/LoadingScreen/Loader';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import services from '../LoginPage/services';
import { AuthenticationContext } from 'utils';

const { Item } = FormBody;
const { Option } = Select;
const { Group } = Input;

enum RegistrationState {
    initialState = 'BASIC REGISTRATION',
    secondState = 'PREFERENCES',
    thirdState = 'EMAIL VERIFICATION',
}

const RegistrationPage = (props: any) => {
    const [form] = FormBody.useForm();

    const { tenantConfig } = React.useContext(AuthenticationContext);

    const [loading, setLoading] = React.useState(false);
    const [registrationState, setRegistrationState] = React.useState(
        RegistrationState.initialState
    );
    const [formValue, setFormValue] = React.useState({});

    const proceedRegistration = async (props: any) => {
        form.validateFields()
            .then((res) => {
                const { name, ...restData } = res;
                setFormValue({
                    ...name,
                    ...restData,
                });

                setRegistrationState(RegistrationState.secondState);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const goBack = () => {
        setRegistrationState(RegistrationState.initialState);
    };

    const onSubmit = () => {
        setLoading(true);
        form.validateFields()
            .then((res) => {
                const { emailNotify, favBrand, newArrivals } = res;
                const updatedValues = {
                    ...formValue,
                    custom_attributes: {
                        emailNotify: emailNotify || false,
                        favBrand,
                        newArrivals: newArrivals || false,
                    },
                };

                services
                    .signup(updatedValues)
                    .then((response) => {
                        notification.success({
                            message: 'Account created successfully!',
                            description: 'Your details have been saved',
                        });
                        setRegistrationState(RegistrationState.thirdState);
                    })
                    .catch((error) => {
                        console.log(error);
                        console.error(error.response);

                        const {
                            data: { status },
                        } = error.response;

                        notification.error({
                            message: status.type,
                            description: status.message,
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch(() => {
                console.error('Some error occured');
                setLoading(false);
            });
    };

    const renderStep = () => {
        switch (registrationState) {
            case RegistrationState.thirdState:
                return <Step3 email={form.getFieldValue(['email'])} />;
            case RegistrationState.secondState:
                return (
                    <Step2
                        goBack={goBack}
                        onSubmit={onSubmit}
                        loading={loading}
                    />
                );
            default:
                return <Step1 proceedRegistration={proceedRegistration} />;
        }
    };

    const renderText = () => {
        switch (registrationState) {
            case RegistrationState.thirdState:
                return <FormHeader> Email Verification Required </FormHeader>;
            case RegistrationState.secondState:
                return <FormHeader> What's your preferences? </FormHeader>;
            default:
                return <FormHeader> Sign Up </FormHeader>;
        }
    };

    return (
        <Wrapper
            style={{ backgroundImage: `url(${tenantConfig.background_image})` }}
        >
            <div className="sid-layy"></div>
            <FormContainer>
                <Logo />
                {renderText()}
                <FormBody form={form}>{renderStep()}</FormBody>
                {registrationState === RegistrationState.initialState && (
                    <Footer style={{ justifyContent: 'center' }}>
                        <TextMuted to="/login">
                            Already have an Account?
                        </TextMuted>
                    </Footer>
                )}
            </FormContainer>
        </Wrapper>
    );
};

export default RegistrationPage;

const Step1 = (props: any) => {
    const autoComplete = 'off';
    return (
        <>
            <Item name="name">
                <Group>
                    <Item
                        name="firstname"
                        noStyle
                        rules={[
                            {
                                required: true,
                                message: 'Please enter first and lastname',
                            },
                        ]}
                    >
                        <Input
                            placeholder="FirstName"
                            style={{ width: '48%', marginRight: '.75rem' }}
                            autoComplete={autoComplete}
                        />
                    </Item>
                    <Item name="lastname" noStyle>
                        <Input
                            placeholder="LastName"
                            style={{ width: '48%' }}
                            autoComplete={autoComplete}
                        />
                    </Item>
                </Group>
            </Item>

            <Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please enter username',
                    },
                ]}
            >
                <Input placeholder="Email" autoComplete={autoComplete} />
            </Item>
            <Item name="phone_number">
                <Input placeholder="Phone Number" autoComplete={autoComplete} />
            </Item>

            <Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
                hasFeedback
            >
                <Input
                    type="password"
                    placeholder="Password"
                    style={{ backgroundColor: '#3b4654' }}
                    autoComplete={autoComplete}
                />
            </Item>
            <Item
                name="password_confirmation"
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please enter confirm password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(
                                    'The two passwords that you entered do not match!'
                                )
                            );
                        },
                    }),
                ]}
                dependencies={['password']}
            >
                <Input
                    type="password"
                    placeholder="Confirm Password"
                    autoComplete={'false'}
                />
            </Item>
            <Button
                type="primary"
                size="large"
                color="default"
                onClick={props.proceedRegistration}
            >
                Next
            </Button>
        </>
    );
};

const Step2 = (props: any) => {
    return (
        <>
            <QuestionsContainer>
                <QuestionBody key="1">
                    <QuestionIndex>1.</QuestionIndex>
                    Do you want to get notification on your mail?
                    <AnswerPortion>
                        <Item name="emailNotify" valuePropName="checked">
                            <Checkbox />
                        </Item>
                    </AnswerPortion>
                </QuestionBody>
            </QuestionsContainer>
            <QuestionsContainer>
                <QuestionBody key="2">
                    <QuestionIndex>2.</QuestionIndex>
                    Do you want to get notifications of our new arrivals?
                    <AnswerPortion>
                        <Item name="newArrivals" valuePropName="checked">
                            <Checkbox />
                        </Item>
                    </AnswerPortion>
                </QuestionBody>
            </QuestionsContainer>
            <QuestionsContainer>
                <QuestionBody key="3">
                    <QuestionIndex>3.</QuestionIndex>
                    Which collections you like the most?
                    <AnswerPortion>
                        <Item name="favBrand" style={{ width: '90%' }}>
                            <Select placeholder="Select">
                                <Option value="trousers"> Trousers</Option>
                                <Option value="shirts"> Shirts</Option>
                                <Option value="footwear"> Footwear </Option>
                            </Select>
                        </Item>
                    </AnswerPortion>
                </QuestionBody>
            </QuestionsContainer>
            <Item
                style={{ margin: '20px 25px' }}
                name="agreement"
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) =>
                            value
                                ? Promise.resolve()
                                : Promise.reject(
                                      new Error('Should accept agreement')
                                  ),
                    },
                ]}
            >
                <Checkbox>
                    <span style={{ color: '#98a4b9', margin: '10px' }}>
                        I have read the{' '}
                        <a href="www.agreement.com" target="_blank">
                            agreement
                        </a>
                    </span>
                </Checkbox>
            </Item>
            <ButtonContainer>
                <Button
                    type="primary"
                    color="danger"
                    size="large"
                    onClick={props.goBack}
                    style={{ width: '45%' }}
                >
                    Back
                </Button>
                <Button
                    type="primary"
                    color="success"
                    onClick={props.onSubmit}
                    size="large"
                    loading={props.loading}
                    style={{ width: '45%' }}
                >
                    Submit
                </Button>
            </ButtonContainer>
        </>
    );
};

const Step3 = (props: any) => {
    const [loading, setLoading] = React.useState(true);

    // const redirectToLoginPage = () => {
    //     return props.history.push('/login');
    // };

    const sendVerificationMail = async () => {
        setLoading(true);

        try {
            const response = await services.sendEmailVerificationMail(
                props.email
            );

            if (!response.error) {
                notification.success({
                    message:
                        response.data?.message || 'Mail sent successfully!',
                });
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        sendVerificationMail();
    }, []);

    return (
        <SendEmailBody>
            {loading ? (
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            ) : (
                <>
                    <Image src={EmailIcon} />
                    <p>
                        Email verification mail has been sent on your e-mail.
                        Please verify to access this portal
                    </p>
                </>
            )}
        </SendEmailBody>
    );
};
