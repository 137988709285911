import React from 'react';
import { notification, Form } from 'antd';

import {
    FormContainer,
    Wrapper,
    Input,
    Button,
    FormBody,
    TextMuted,
    Footer,
    FormHeader,
    SocialLoginWrapper,
    SocialLogin,
} from 'common/styled';

import GoogleLogo from 'assets/google.png';
import TwitterLogo from 'assets/twitter.png';
import FBLogo from 'assets/facebook.png';

import Logo from 'components/Logo';

import service from './services';
import { AuthenticationContext } from 'utils';

const Login = (props: any) => {
    const [form] = Form.useForm();

    const { setUser, setScreenLoading, setAuthenticated, tenantConfig } =
        React.useContext(AuthenticationContext);

    const [loading, setLoading] = React.useState(false);

    const redirectToFacebook = () => {
        window.location.href = `https://gslab-instance-dev.onelogin.com/access/initiate?iss=https://facebook.com&target_link_uri=${process.env.REACT_APP_UI_DOMAIN}?check-login=true`;
    };

    const redirectToGoogle = () => {
        window.location.href = `https://gslab-instance-dev.onelogin.com/access/initiate?iss=https://accounts.google.com&target_link_uri=${process.env.REACT_APP_UI_DOMAIN}?check-login=true
        `;
    };

    const login = async (values: any) => {
        setLoading(true);

        const { username, password } = values;

        try {
            const response = await service.login(username, password);

            const session_token = response.data.session_token;

            setUser(response.data.user);

            sessionStorage.setItem('user', JSON.stringify(response.data.user));

            // const session_response = await service.create_session(
            //     session_token
            // );

            await service.create_session(session_token);

            notification.success({
                message: 'Successfully logged in',
            });

            setScreenLoading(true);
            setAuthenticated(true);
            // if (session_response) {
            //     window.location.replace(
            //         'https://gsabhi-dev.onelogin.com/oidc/2/auth?client_id=20b80d00-5410-0139-fb93-0ac6abe4ea43185165&redirect_uri=https://ui.ciam.workers.dev/&response_type=id_token&scope=openid&nonce=abcbde'
            //     );
            // }
        } catch (err) {
            const { data } = err.response;

            notification.error({
                message: data.type,
                description: data.message,
            });
        } finally {
            setScreenLoading(false);
            setLoading(false);
        }
    };

    return (
        <Wrapper
            style={{ backgroundImage: `url(${tenantConfig.background_image})` }}
        >
            <div className="sid-layy"></div>

            <FormContainer>
                <Logo />
                <FormHeader>Login into your account</FormHeader>
                <FormBody form={form} onFinish={login}>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter username',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Username"
                            autoComplete="off"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter password',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Password"
                            type="password"
                            autoComplete="off"
                            size="large"
                        />
                    </Form.Item>

                    <Button
                        htmlType="submit"
                        size="large"
                        color="default"
                        type="primary"
                        loading={loading}
                    >
                        Login
                    </Button>
                </FormBody>

                <SocialLoginWrapper>
                    <span>OR</span>
                    <SocialLogin onClick={redirectToGoogle}>
                        <img src={GoogleLogo} alt="" />
                    </SocialLogin>
                    <SocialLogin>
                        <img src={TwitterLogo} alt="" />
                    </SocialLogin>
                    <SocialLogin onClick={redirectToFacebook}>
                        <img src={FBLogo} alt="" />
                    </SocialLogin>
                </SocialLoginWrapper>
                <Footer>
                    <TextMuted to="/forgotpassword">
                        Forgot your password?
                    </TextMuted>
                    <TextMuted to="/register">Create an Account</TextMuted>
                </Footer>
            </FormContainer>
            {/* </RightContent> */}
        </Wrapper>
    );
};

export default Login;
