import React, { useContext, useState } from 'react';
import {
    FormBody,
    FormContainer,
    Container,
    Input,
    Button,
    ButtonContainer,
    Footer,
    Wrapper,
    FormHeader,
} from './styled';

import services from './services';

import { history } from 'App';
import { notification } from 'antd';
import { AuthenticationContext } from 'utils';

const { Item } = FormBody;

const ChangePassword = (props: any) => {
    const { user, tenantConfig } = useContext(AuthenticationContext);
    const [loading, setLoading] = useState(false);

    const [form] = FormBody.useForm();

    const onBack = () => {
        history.goBack();
    };

    const submitHandler = () => {
        form.validateFields()
            .then((res: any) => {
                setLoading(true);
                services
                    .resetLink({ ...res, id: user.id })
                    .then((res: any) => {
                        notification.success({
                            message: 'Password updation complete!',
                            description: 'To check, Try login again!',
                        });
                        props.history.replace('/');
                    })
                    .catch((error) => {
                        notification.error({
                            message:
                                error.response?.data ||
                                'Unable to reset password!',
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch((error: any) => {
                console.error(error);
                notification.error({
                    message: 'Please enter required fields',
                });
            });
    };

    return (
        <Wrapper
            style={{
                height: 'calc(100vh - 50px)',
                backgroundImage: `url(${tenantConfig.background_image})`,
            }}
        >
            <div className="sid-layy"></div>
            <FormContainer>
                <FormHeader> Reset Password</FormHeader>

                <FormBody form={form}>
                    <Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter new password!',
                            },
                        ]}
                    >
                        <Input
                            type="password"
                            placeholder="New Password"
                            autoComplete={'false'}
                        />
                    </Item>
                    <Item
                        name="password_confirmation"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please enter confirm password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            'The two passwords that you entered do not match!'
                                        )
                                    );
                                },
                            }),
                        ]}
                        dependencies={['password']}
                    >
                        <Input
                            type="password"
                            placeholder="Confirm Password"
                            autoComplete={'false'}
                        />
                    </Item>
                </FormBody>
                <Footer>
                    <ButtonContainer>
                        <Button
                            color="danger"
                            size="large"
                            onClick={onBack}
                            style={{ width: '45%' }}
                        >
                            Back
                        </Button>
                        <Button
                            loading={loading}
                            style={{ width: '45%' }}
                            size="large"
                            htmlType="submit"
                            onClick={submitHandler}
                        >
                            Submit
                        </Button>
                    </ButtonContainer>
                </Footer>
            </FormContainer>
        </Wrapper>
    );
};

export default ChangePassword;
