import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import { Button as AntdButton, Form, Input as AntdInput } from 'antd';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

export const Content = styled.div`
    height: calc(100% - 50px);
    width: 100%;
`;

export const ContentHeader = styled.h1`
    color: ${({ theme }) => theme.homePageContent.fontColor};
    text-align: left;
    padding: 10px;
`;

export const Card = styled(Link)`
    border-radius: 0.25rem;
    width: 250px;
    min-height: 250px;
    text-transform: uppercase;
    font-size: 25px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.homePageContent.card.borderColor} !important;
    text-align: center;
    font-weight: 500;
    color: ${({ theme }) => theme.homePageContent.card.fontColor} !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: all 0.1s ease-in-out;
    /* box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16),
        0 3px 6px rgba(255, 255, 255, 0.23); */
    span {
        display: block;
    }
    a {
        color: ${({ theme }) =>
            theme.homePageContent.card.fontColor} !important;
    }
    &:hover {
        transform: scale(1.01);
    }
`;

export const CardBody = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.homePageContent.card.fontColor} !important;
    flex-direction: column;
    justify-content: center;
`;
export const Body = styled.div`
    color: ${({ theme }) => theme.homePageContent.card.fontColor};
    font-size: 20px;
`;

export * from 'common/styled';
